import React, { useMemo, useState } from "react";
import { PushNotificationParameters } from "app/hooks/v2/pushNotification/usePushNotificationParameters";

import styles from "./PushNotificationLists.module.scss";

import Tabs from "molecules/TableTab/Tabs";
import ScheduledCampaignList from "./ScheduledCampaignList";
import DraftCampaignList from "./DraftCampaignList";
import JobHistoryList from "./JobHistoryList";
import {
  PushNotificationPageCallbacks,
  PushNotificationPageParameters,
} from "app/hooks/v2/pushNotification/usePushNotificationPage";
import {
  CancelScheduleModal,
  DeleteCampaignModal,
  CopyCampaignModal,
} from "./PushNotificationConfirmModals";
import {
  PUSH_NOTIFICATION_GENRE_TYPE,
  PushNotificationGenreListType,
} from "app/system_defaults/v2_routing";

const PUSH_NOTIFICATION_LIST_TYPE_LABELS = {
  [PUSH_NOTIFICATION_GENRE_TYPE.SCHEDULED]: "予約中",
  [PUSH_NOTIFICATION_GENRE_TYPE.DRAFT]: "下書き",
  [PUSH_NOTIFICATION_GENRE_TYPE.DELIVERED]: "配信済み",
} as const;

const GENRE_ORDERS = [
  PUSH_NOTIFICATION_GENRE_TYPE.SCHEDULED,
  PUSH_NOTIFICATION_GENRE_TYPE.DRAFT,
  PUSH_NOTIFICATION_GENRE_TYPE.DELIVERED,
] as const;

const GeneratePushNotificationList: React.VFC<{
  type: PushNotificationGenreListType;
  parameters: PushNotificationParameters;
  callbacks: PushNotificationPageCallbacks;
}> = ({ type, parameters, callbacks }): JSX.Element => {
  const scheduleCampaignsElement = useMemo(
    () => (
      <ScheduledCampaignList
        campaigns={parameters.scheduledCampaigns}
        changeStatus={(campaignUuid: string, isActive: boolean) => {
          callbacks.updateModalContents(
            <CancelScheduleModal
              clearModalElement={callbacks.closeModalContents}
              submit={() => {
                callbacks.events.updateCampaignStatus(campaignUuid, isActive);
                callbacks.closeModalContents();
              }}
            />
          );
        }}
        showContents={(contents: JSX.Element) => {
          callbacks.updateModalContents(contents);
        }}
        hideContents={() => {
          callbacks.closeModalContents();
        }}
      />
    ),
    [parameters.scheduledCampaigns, callbacks]
  );

  const draftCampaignsElement = useMemo(
    () => (
      <DraftCampaignList
        campaigns={parameters.draftCampaigns}
        deleteCampaign={(campaignUuid: string) => {
          callbacks.updateModalContents(
            <DeleteCampaignModal
              clearModalElement={callbacks.closeModalContents}
              submit={() => {
                callbacks.events.deleteCampaign(campaignUuid);
                callbacks.closeModalContents();
              }}
            />
          );
        }}
        editCampaign={callbacks.events.editCampaign}
        copyCampaign={(campaignUuid: string) => {
          callbacks.updateModalContents(
            <CopyCampaignModal
              clearModalElement={callbacks.closeModalContents}
              submit={() => {
                callbacks.events.copyCampaign(campaignUuid);
                callbacks.closeModalContents();
              }}
            />
          );
        }}
        showContents={(contents: JSX.Element) => {
          callbacks.updateModalContents(contents);
        }}
        hideContents={() => {
          callbacks.closeModalContents();
        }}
      />
    ),
    [parameters.draftCampaigns, callbacks]
  );

  const jobHistoriesElement = useMemo(
    () => (
      <JobHistoryList
        histories={parameters.jobHistories}
        copyHistory={(campaignUuid: string, jobId: number) => {
          callbacks.updateModalContents(
            <CopyCampaignModal
              clearModalElement={callbacks.closeModalContents}
              submit={() => {
                callbacks.events.copyJobHistory(campaignUuid, jobId);
                callbacks.closeModalContents();
              }}
            />
          );
        }}
        showContents={(contents: JSX.Element) => {
          callbacks.updateModalContents(contents);
        }}
        hideContents={() => {
          callbacks.closeModalContents();
        }}
        loadNextPage={callbacks.events.loadNextJobHistory}
      />
    ),
    [parameters.jobHistories, callbacks]
  );

  const listElements = useMemo(() => {
    return {
      [PUSH_NOTIFICATION_GENRE_TYPE.SCHEDULED]: scheduleCampaignsElement,
      [PUSH_NOTIFICATION_GENRE_TYPE.DRAFT]: draftCampaignsElement,
      [PUSH_NOTIFICATION_GENRE_TYPE.DELIVERED]: jobHistoriesElement,
    };
  }, [scheduleCampaignsElement, draftCampaignsElement, jobHistoriesElement]);

  return listElements[type];
};

const PushNotificationLists: React.VFC<{
  parameters: PushNotificationPageParameters;
  callbacks: PushNotificationPageCallbacks;
}> = ({ parameters, callbacks }) => {
  const [listType, setListType] = useState<PushNotificationGenreListType>(
    parameters.searchParameters.genre
  );

  const genreChangeTab = useMemo(
    () => (
      <Tabs
        tabProps={GENRE_ORDERS.map((e) => {
          return {
            label: (
              <div style={{ color: listType === e ? "#ff7b2c" : "#818181" }}>
                {PUSH_NOTIFICATION_LIST_TYPE_LABELS[e]}
              </div>
            ),
            onClick: () => setListType(e),
            is_active: listType === e,
          };
        })}
      />
    ),
    [listType]
  );

  return (
    <div className={styles.pushNotificationLists}>
      <div>{genreChangeTab}</div>
      <div className={styles.list}>
        <GeneratePushNotificationList
          type={listType}
          parameters={parameters.data}
          callbacks={callbacks}
        />
      </div>
    </div>
  );
};

export default PushNotificationLists;
