import React from "react";
import LoopIcon from "images/icons/icon_loop.svg";
import { ScheduledCampaign } from "types/campaign";
import OrangeHeaderTable, {
  OrangeHeaderTableRecord,
} from "atoms/ezPushShared/accountPageShared/OrangeHeaderTable";
import { unixTimeToDateTime } from "utils/format/unixtime";
import OrangeText from "atoms/ezPushShared/accountPageShared/OrangeText";
import { PreviewModal } from "./PushNotificationListModals";
import PushNotificationPreviewer from "./PushNotificationPreviewer";
import { DescriptionFormatter } from "utils/format/DescriptionFormatter";
import LinkText from "atoms/LinkText";
import NotificationTitle from "molecules/v2/NotificationTitle";
import DeliveryScheduleRuleDescription from "molecules/v2/DeliveryScheduleRuleDescription";
import VerticalTable from "atoms/ezPushShared/accountPageShared/VerticalTable";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";

const HEADER_NAMES = [
  "配信予定日時",
  "管理名",
  "セグメント指定",
  "想定対象者数",
  "配信サイクル",
  "操作",
];

const getDetailList = (elem: ScheduledCampaign) => {
  const detailList = [
    {
      label: "遷移先URL",
      value: (
        <LinkText
          text={elem.contents.redirect_url}
          link={elem.contents.redirect_url}
          external={true}
        />
      ),
    },
  ];
  if (elem.delivery_schedule_rule.frequency.frequency_type !== "ONCE") {
    detailList.push({
      label: "配信サイクル",
      value: (
        <DeliveryScheduleRuleDescription rule={elem.delivery_schedule_rule} />
      ),
    });
  }
  return detailList;
};

const ScheduledCampaignList: React.VFC<{
  campaigns: ScheduledCampaign[] | null;
  changeStatus: (campaignUuid: string, isActive: boolean) => void;
  showContents: (contents: JSX.Element) => void;
  hideContents: () => void;
}> = ({ campaigns, changeStatus, showContents, hideContents }) => {
  const formatter = new DescriptionFormatter();

  const scheduledCampaignList: OrangeHeaderTableRecord[] | null =
    campaigns === null
      ? null
      : campaigns.map((elem) => {
          const previewModal = (
            <PreviewModal
              previewer={
                <VerticalSpreadLayout
                  align="center"
                  items={[
                    <div style={{ margin: "30px" }}>
                      <PushNotificationPreviewer
                        title={elem.contents.title}
                        body={elem.contents.body}
                        imageUrl={elem.contents.image_url}
                      />
                    </div>,
                    <VerticalTable items={getDetailList(elem)} />,
                  ]}
                />
              }
              close={hideContents}
            />
          );

          const cancelButton = (
            <OrangeText
              text="予約をキャンセル"
              onChange={() => changeStatus(elem.campaign_uuid, false)}
            />
          );

          return {
            cells: [
              { item: unixTimeToDateTime(elem.next_at), width: 108 },
              {
                item: (
                  <NotificationTitle
                    mark={
                      elem.delivery_schedule_rule.frequency.frequency_type !==
                      "ONCE"
                        ? LoopIcon
                        : undefined
                    }
                    title={formatter.substr(elem.name, 100)}
                    onClickPreviewButton={() => showContents(previewModal)}
                  />
                ),
                width: 280,
              },
              {
                item: formatter.substr(elem.destination_collect_rule.name),
                width: 200,
              },
              {
                item:
                  elem.destination_collect_rule.destination_count !== null
                    ? formatter.addCommasToNumber(
                        elem.destination_collect_rule.destination_count
                      )
                    : "集計中",
                isAlignRight: true,
              },
              {
                item: (
                  <DeliveryScheduleRuleDescription
                    rule={elem.delivery_schedule_rule}
                  />
                ),
                width: 180,
              },
              {
                item: cancelButton,
                width: 140,
              },
            ],
          };
        });

  return (
    <VerticalSpreadLayout
      items={[
        <div>
          ※想定対象者数は「配信予定時間の1時間前」のタイミングで更新されます。
        </div>,
        <OrangeHeaderTable
          headers={HEADER_NAMES}
          records={scheduledCampaignList}
          blankMessage="予約中のプッシュ通知設定はありません"
        />,
      ]}
    />
  );
};

export default ScheduledCampaignList;
