import { useCallback, useEffect, useState } from "react";
import { Domain } from "types/domain";
import DomainRepository from "utils/repositories/DomainRepository";
import RestApi from "utils/RestApi";

const useGrantMessage = (managementApi: RestApi): [Domain[] | null] => {
  const [domains, setDomains] = useState<Domain[] | null>(null);

  const loadDomain = useCallback(async () => {
    try {
      setDomains(null);
      const domainRepository = new DomainRepository(managementApi);
      const response = await domainRepository.get();
      if (response) {
        setDomains(response.items);
      }
    } catch (error) {
      console.error(error);
    }
  }, [managementApi]);

  useEffect(() => {
    loadDomain();
  }, [loadDomain]);

  return [domains];
};

export default useGrantMessage;
