import React, { useMemo } from "react";
import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import AccountPageBase from "./AccountPageBase";
import usePushNotificationPage from "app/hooks/v2/pushNotification/usePushNotificationPage";
import PushNotificationPanel from "organisms/ezPush/pushNotification/PushNotificationPanel";

const PushNotificationPage: React.VFC<{
  accountContext: AccountPageContextV2;
}> = ({ accountContext }) => {
  const [parameters, callbacks] = usePushNotificationPage(
    accountContext.managementApi
  );

  const page = useMemo(() => {
    return (
      <PushNotificationPanel parameters={parameters} callbacks={callbacks} />
    );
  }, [parameters, callbacks]);

  const pageBase = useMemo(() => {
    return (
      <AccountPageBase
        context={accountContext}
        areaContents={page}
        modalContents={parameters.modalContents}
      />
    );
  }, [accountContext, page, parameters.modalContents]);
  return pageBase;
};

export default PushNotificationPage;
