import { useCallback, useEffect, useState } from "react";
import { JobHistories, JobHistoryResponse } from "types/job_history";
import RestApi from "utils/RestApi";
import JobHistoryRepository from "utils/repositories/JobHistoryRepository";

const useJobHistories = (
  managementApi: RestApi
): [JobHistories, (targetPage: number) => void] => {
  const [jobHistories, setJobHistories] = useState<JobHistoryResponse[]>([]);
  const [loadingPages, setLoadingPages] = useState<number[]>([]);
  const [totalHistoryCount, setTotalHistoryCount] = useState<number | null>(
    null
  );

  const loadJobHistories = useCallback(
    (targetPage: number) => {
      // NOTE: すでに読み込み済みのページは読み込まない
      if (
        loadingPages.includes(targetPage) ||
        jobHistories.filter((history) => history.page === targetPage).length > 0
      ) {
        return;
      }

      setLoadingPages((prev) => [...prev, targetPage]);

      new JobHistoryRepository(managementApi)
        .load(targetPage)
        .then((response) => {
          if (response) {
            setJobHistories((prev) => {
              if (prev.length > 0) {
                return [...prev, response];
              }
              return [response];
            });
            setTotalHistoryCount(response.total);
            setLoadingPages((prev) =>
              prev.filter((page) => page !== targetPage)
            );
          }
        });
    },
    [managementApi, loadingPages, jobHistories]
  );

  useEffect(() => {
    loadJobHistories(0);
  }, [loadJobHistories]);

  return [
    {
      items: jobHistories,
      total: totalHistoryCount,
    },
    loadJobHistories,
  ];
};

export default useJobHistories;
