import styles from "./PushNotificationPreviewer.module.scss";

const DummyImage = () => {
  return (
    <div className={styles.dummyImage}>
      <div className={styles.text}>IMAGE</div>
    </div>
  );
};

const PushNotificationPreviewer: React.VFC<{
  title: string | null;
  body: string | null;
  imageUrl: string | null;
  isDummy?: boolean;
}> = ({ title, body, imageUrl, isDummy = false }) => {
  const isInput = title || body || imageUrl;

  const dummyElement: JSX.Element = isDummy ? (
    <div className={styles.imageFrame}>
      <DummyImage />
    </div>
  ) : (
    <></>
  );

  return (
    <div className={styles.pushNotificationContentsPreviewer}>
      {imageUrl ? (
        <div className={styles.imageFrame}>
          <img className={styles.image} src={encodeURI(imageUrl)} alt="" />
        </div>
      ) : (
        dummyElement
      )}
      <div className={styles.textArea}>
        <div className={styles.title}>
          {title && title.length > 0
            ? title
            : isInput
            ? ""
            : "見出しテキストが反映されます"}
        </div>
        <div className={styles.body}>
          {body && body.length > 0
            ? body
            : isInput
            ? ""
            : "本文テキストが反映されます"}
        </div>
      </div>
    </div>
  );
};

export default PushNotificationPreviewer;
