import { useCallback, useEffect, useState } from "react";
import RestApi from "utils/RestApi";

import CampaignRepository from "utils/repositories/CampaignRepository";
import { DraftCampaign } from "types/campaign";

const useDraftCampaign = (
  managementApi: RestApi
): [DraftCampaign[] | null, () => void] => {
  const [draftCampaigns, setDraftCampaigns] = useState<DraftCampaign[] | null>(
    null
  );

  const load = useCallback(() => {
    setDraftCampaigns(null);
    new CampaignRepository(managementApi).loadDraft().then((response) => {
      if (response) {
        setDraftCampaigns(response.items);
      }
    });
  }, [managementApi]);

  useEffect(() => {
    load();
  }, [load]);

  return [draftCampaigns, load];
};

export default useDraftCampaign;
